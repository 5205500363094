import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import AdditionalInfo from 'components/AdditionalInfo';
import ButtonToTop from 'components/ButtonToTop';
import NewsArticleBanner from 'components/NewsArticleBanner';
import NewsArticleTitle from 'components/NewsArticleTitle';

import './NewsArticlePage.scss';

const NewsArticlePage: FC<NewsArticlePageTypes.NewsArticlePageProps> = ({
  data: {
    newsArticlePage: {
      title: newsTitle,
      subTitle,
      createDate,
      urls,
      seo,
      newsArticleBanner: [bannerContent],
      additionalInfo,
      secondaryTagNavigation,
    },
    buttonToTopStructure,
  },
}) => {
  const { title, keywords, description } = seo;
  const { bannerImage, bottomLineText, bottomLineLogo, bottomLineLink, bottomLineLinkAria } =
    bannerContent || {};

  return (
    <Layout headerTransparent className="news-article">
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <NewsArticleBanner
        title={newsTitle}
        createDate={createDate}
        bottomLineText={bottomLineText}
        bottomLineLogo={bottomLineLogo}
        bottomLineLink={bottomLineLink}
        bottomLineLinkAria={bottomLineLinkAria}
        bannerImage={bannerImage}
      />
      <NewsArticleTitle subTitle={subTitle} secondaryTagNavigation={secondaryTagNavigation} />
      <AdditionalInfo {...{ ...additionalInfo }} />
      <ButtonToTop buttonToTop={buttonToTopStructure.buttonToTop} />
    </Layout>
  );
};

export const query = graphql`
  query NewsArticleQuery($lang: String, $link: String = "") {
    newsArticlePage(lang: { eq: $lang }, url: { eq: $link }) {
      title
      subTitle
      createDate
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      newsArticleBanner {
        structure
        bannerImage {
          ...UmbracoImage
        }
        bottomLineText
        bottomLineLink
        bottomLineLinkAria
        bottomLineLogo {
          ...UmbracoImage
        }
      }
      additionalInfo {
        ...AdditionalInfoType
      }
      secondaryTagNavigation {
        id
        url {
          url
        }
        name
        title
      }
    }
    buttonToTopStructure: newsArticleStructure {
      buttonToTop {
        ariaLabel
        backgroundColor
        label
        link {
          url
          name
        }
        size
      }
    }
  }
`;

export default NewsArticlePage;
