import React, { FC } from 'react';
import dayjs from 'dayjs';

import Image from 'common/Image';

import './NewsArticleBanner.scss';

const NewsArticleBanner: FC<
  NewsArticlePageTypes.NewsArticleTitle &
    NewsArticlePageTypes.CreateDateItem &
    NewsArticlePageTypes.NewsArticleBanner
> = ({
  title,
  createDate,
  bottomLineText,
  bottomLineLink,
  bottomLineLinkAria,
  bottomLineLogo,
  bannerImage,
}) => (
  <div className="news-article-banner">
    <div className="news-article-banner__info">
      <h1 className="news-article-banner__title">{title}</h1>
      <p className="news-article-banner__date">{dayjs(createDate).format('DD MMMM YYYY')}</p>
    </div>
    {bannerImage ? (
      <Image key={bannerImage.altText} imageData={bannerImage} alt={bannerImage.altText} />
    ) : null}
    {bottomLineText && bottomLineLogo ? (
      <div className="news-article-banner__bottom-line">
        <div className="news-article-banner__bottom-line-text">{bottomLineText}</div>
        <a
          href={bottomLineLink}
          aria-label={bottomLineLinkAria}
          className="news-article-banner__bottom-line-logo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            key={bottomLineLogo.altText}
            imageData={bottomLineLogo}
            alt={bottomLineLogo.altText}
            objectFit="contain"
          />
        </a>
      </div>
    ) : null}
  </div>
);

export default NewsArticleBanner;
