import React, { FC } from 'react';

import Button from 'common/Button';

import './ButtonToTop.scss';

const ButtonToTop: FC<{ buttonToTop: PageContent.ButtonType }> = ({ buttonToTop }) => {
  const { ariaLabel, backgroundColor, size, label } = buttonToTop;

  return (
    <div className="button-to-top" data-testid="button-to-top">
      {buttonToTop ? (
        <Button
          data-testid="button-to-top__button"
          className="btn--sign-up"
          ariaLabel={ariaLabel}
          backgroundColor={backgroundColor}
          size={size}
          type="button"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
          label={label}
        />
      ) : null}
    </div>
  );
};

export default ButtonToTop;
