import React, { FC } from 'react';

import ArticleTagsList from 'components/ArticleTagsList';

import './NewsArticleTitle.scss';

const NewsArticleTitle: FC<
  NewsArticlePageTypes.SecondaryTagNavigation & NewsArticlePageTypes.NewsArticleSubTitle
> = ({ secondaryTagNavigation, subTitle }) => (
  <div className="article-info container" data-testid="article-info">
    <h2 className="article-subtitle" data-testid="article-subtitle">
      {subTitle}
    </h2>
    <ArticleTagsList tags={secondaryTagNavigation} />
  </div>
);

export default NewsArticleTitle;
